import React, { useLayoutEffect, useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import * as classes from "./carousel.module.scss";
import Carousel from "../../components/Carousel/Carousel";
import hastToHyperscript from "hast-to-hyperscript";
import useGlossary from "../../hooks/useGlossary";
const { Carousel: CarouselClass, Heading, Content } = classes;

const Project = props => {
  useLayoutEffect(() => {
    const overflowing = checkOverflow(contentRef.current);
    if (overflowing) {
      contentRef.current.classList.add(classes.withFadeOut);
      readMoreRef.current.classList.add(classes.active);
    }
  });

  const contentRef = useRef(null);
  const readMoreRef = useRef(null);
  const wrapperRef = useRef(null);

  useGlossary(contentRef);

  const expandToggle = () => {
    const container = contentRef.current;
    const wrapper = wrapperRef.current;
    container.classList.toggle(classes.withFadeOut);
    container.classList.toggle(classes.expanded);
    wrapper.classList.toggle(classes.expanded);
  };

  const mediaItems = props.data.carousel.frontmatter.mediaItems
    ? props.data.carousel.frontmatter.mediaItems
    : [];

  const { htmlAst, fileAbsolutePath } = props.data.carousel;
  const titleAst = htmlAst.children[0];
  let title = null;
  let content = hastToHyperscript(React.createElement, htmlAst);

  if (titleAst && titleAst.tagName === "h1") {
    // delete htmlAst.children[0]
    const [_, ...childrenRest] = htmlAst.children;
    const contentAst = {
      ...htmlAst,
      children: childrenRest,
    };

    content = hastToHyperscript(React.createElement, contentAst);
    title = hastToHyperscript(React.createElement, titleAst);
  }

  const noCarousel = mediaItems.length === 0;
  const year = props.data.carousel.frontmatter.year;
  const job = props.data.carousel.frontmatter.job;

  return (
    <Layout breadCrumb={props.pageContext.breadCrumb}>
      <div
        className={`${CarouselClass} ${noCarousel ? classes.noCarousel : null}`}
      >
        <div className={Heading}>{title}</div>
        {noCarousel ? null : (
          <div className={classes.carouselWrapper}>
            <Carousel
              file={fileAbsolutePath}
              buttonsUnder
              mediaItems={mediaItems}
            />
          </div>
        )}
        {/* <div className={Content} dangerouslySetInnerHTML={{ __html: props.data.carousel.html }} /> */}
        <div className={classes.Wrapper} ref={wrapperRef}>
          <div className={Content} ref={contentRef}>
            <div className={classes.contentHeading}>{title}</div>
            {content}
            <button
              ref={readMoreRef}
              onClick={expandToggle}
              className={classes.readMoreButton}
            ></button>
          </div>
          <div className={classes.details}>
            {job && <p>Aufgabe: {job}</p>}
            {year && <p>Jahr: {year}</p>}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($absolutePath: String) {
    carousel: markdownRemark(fileAbsolutePath: { eq: $absolutePath }) {
      fileAbsolutePath
      frontmatter {
        year
        job
        title
        mediaItems {
          subtitle
          videoUrl
          thumbnail_path {
            absolutePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH),
            }
          }
          path {
            absolutePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH),
            }
          }
        }
      }
      html
      htmlAst
    }
  }
`;

const checkOverflow = el => {
  const isOverflowing =
    el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;

  return isOverflowing;
};

export default Project;
