import * as classes from "./Carousel.module.scss";

// Gets the positions of left and right edges
const getHozEdges = el => {
  return {
    left: el.getBoundingClientRect().left,
    right: el.getBoundingClientRect().right,
  };
};

export const compact = arr => {
  return arr.reduce((arr, el) => {
    if (!!el) {
      return arr.concat(el);
    } else return arr;
  }, []);
};

export const checkThumbVisible = index => {
  let checkObject = {
    visible: true,
    ammount: null,
  };
  const currentThumb = document.querySelector(`#wrapper-${index}`);
  const thumbnails = document.querySelector(`.${classes.thumbnails}`);
  const currentThumbEdges = getHozEdges(currentThumb);
  const thumbnailsEdges = getHozEdges(thumbnails);

  if (currentThumbEdges.left < thumbnailsEdges.left) {
    checkObject = {
      visible: false,
      ammount: currentThumbEdges.left - thumbnailsEdges.left,
    };
  }

  if (currentThumbEdges.right > thumbnailsEdges.right) {
    checkObject = {
      visible: false,
      ammount: currentThumbEdges.right - thumbnailsEdges.right,
    };
  }
  return checkObject;
};
