// extracted by mini-css-extract-plugin
export var Carousel = "Carousel-module--Carousel--3f53f";
export var ReactCollapseCollapse = "Carousel-module--ReactCollapse--collapse--05532";
export var buttonsUnder = "Carousel-module--buttonsUnder--e4b94";
export var contactDetails = "Carousel-module--contactDetails--adcc1";
export var contactGrid = "Carousel-module--contactGrid--a6de5";
export var glossaryDescription = "Carousel-module--glossary-description--3cb7b";
export var glossaryFadeIn = "Carousel-module--glossaryFadeIn--362f6";
export var glossaryTerm = "Carousel-module--glossary-term--b510c";
export var impressum = "Carousel-module--impressum--d8ef4";
export var layout = "Carousel-module--layout--82500";
export var legal = "Carousel-module--legal--3d908";
export var nextButton = "Carousel-module--nextButton--a13a6";
export var previousButton = "Carousel-module--previousButton--708df";
export var slide = "Carousel-module--slide--3e87b";
export var thumbWrapper = "Carousel-module--thumbWrapper--b18b2";
export var thumbnail = "Carousel-module--thumbnail--9829e";
export var thumbnailActive = "Carousel-module--thumbnailActive--2daaa";
export var thumbnails = "Carousel-module--thumbnails--7f384";