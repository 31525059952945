import React from "react";
import * as classes from "./Video.module.scss";

const Video = ({ style, src }) => {
  return (
    <div style={style} class={classes.Video}>
      <iframe
        src={src}
        allowFullScreen
        frameborder="0"
        autoPlay="false"
      ></iframe>
    </div>
  );
};

export default Video;
