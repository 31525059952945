import { useLayoutEffect, useContext } from "react";
import { GlossaryContext } from "../components/ContextProvider";

// Not in use
function typeTextIntoNode(node, text) {
  const charArr = text.split("");
  let count = 0;
  const interval = setInterval(() => {
    if (count >= charArr.length - 1) {
      clearInterval(interval);
    }
    node.textContent += charArr[count];
    count++;
  }, 30);
}

function getClickListener(el, glossary) {
  return e => {
    e.preventDefault();

    // If def is open, close it.
    if (el.dataset.open === "true") {
      el.nextSibling.remove();
      el.dataset.open = "false";
      return;
    }

    // Get definition
    let glossaryKey = el.textContent.toLowerCase();
    const withRefPattern = /glossary:.+?/gm;
    const refPrefix = "glossary:";
    const href = el.getAttribute("href");
    if (withRefPattern.test(href)) {
      // Need to decode in case of umlauts.
      glossaryKey = decodeURI(href.slice(refPrefix.length)).toLowerCase();
    }
    const def = glossary[glossaryKey];
    if (def == null) return;
    const defSpan = document.createElement("span");
    const textContent = ` [${def}]`;

    el.dataset.open = "true";
    el.parentNode.insertBefore(defSpan, el.nextSibling);
    defSpan.className = "glossary-description";
    defSpan.textContent = textContent;
  };
}

const useGlossary = contentRef => {
  const glossary = useContext(GlossaryContext);

  useLayoutEffect(() => {
    if (!contentRef.current || !(contentRef.current instanceof HTMLElement))
      return;
    const contentEl = contentRef.current;
    const terms = contentEl.querySelectorAll("a[href^=glossary]");
    terms.forEach(t => {
      t.className = "glossary-term";
      t.addEventListener("click", getClickListener(t, glossary));
    });
  });
};

export default useGlossary;
