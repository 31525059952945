// extracted by mini-css-extract-plugin
export var Carousel = "carousel-module--Carousel--6fff0";
export var Content = "carousel-module--Content--9900a";
export var FeaturedImage = "carousel-module--FeaturedImage--205e6";
export var Heading = "carousel-module--Heading--7312d";
export var ReactCollapseCollapse = "carousel-module--ReactCollapse--collapse--5f258";
export var Wrapper = "carousel-module--Wrapper--18f98";
export var active = "carousel-module--active--08991";
export var carouselWrapper = "carousel-module--carouselWrapper--0e757";
export var contactDetails = "carousel-module--contactDetails--e5b73";
export var contactGrid = "carousel-module--contactGrid--6cd33";
export var contentHeading = "carousel-module--contentHeading--ba378";
export var details = "carousel-module--details--998ec";
export var expanded = "carousel-module--expanded--826aa";
export var glossaryDescription = "carousel-module--glossary-description--808cc";
export var glossaryFadeIn = "carousel-module--glossaryFadeIn--2096c";
export var glossaryTerm = "carousel-module--glossary-term--75fa0";
export var impressum = "carousel-module--impressum--f5906";
export var layout = "carousel-module--layout--0135b";
export var legal = "carousel-module--legal--8619b";
export var noCarousel = "carousel-module--noCarousel--ae8a2";
export var readMoreButton = "carousel-module--readMoreButton--d9c13";
export var withFadeOut = "carousel-module--withFadeOut--ebc62";